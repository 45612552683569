import React from "react";
import { Link } from "gatsby";
import { Layout } from "src/layouts";
import {
  Container,
  ContentSection,
  ImageContainer,
  AppImageSection,
  LinksContainer,
} from "src/components/ProductDetails";
import AppStore from "assets/AppStore.inline.svg";
import { getTuned } from "src/utilities/projectData";
import GetTuned1 from "assets/GetTuned1.inline.png";
import GetTuned2 from "assets/GetTuned2.inline.png";
import GetTuned3 from "assets/GetTuned3.inline.png";

const GetTuned = () => {
  return (
    <Layout>
      <Container>
        <article>
          <h1>{getTuned.title}</h1>
          <ImageContainer>
            <img
              src={getTuned.imageUrl}
              alt="GetTuned app icon"
              className="appIcon"
            />
            <p>{getTuned.description}</p>
            <LinksContainer>
              <a href={getTuned.appStoreUrl}>
                <AppStore alt="Download on the AppStore" />
              </a>
            </LinksContainer>
          </ImageContainer>
          <ContentSection>
            <p>
              GetTuned (formerly known as Tunelyzer) is a tuner that works with
              all of your instruments. It has presets for common guitar tunings,
              it can be adjusted to use a fundamental of 432Hz instead of 440Hz
              (if that's your thing), and there's a Chromatic mode that works
              with any instrument.
            </p>
            <p>
              This app is written in UIKit using programmatic layout, and I'm
              currently in the process of converting parts of it to SwiftUI
              (mainly the chromatic view, which desperately needs improvement).
            </p>
            <AppImageSection>
              <img src={GetTuned1} alt="Product image 1" className="appImage" />
              <img src={GetTuned2} alt="Product image 2" className="appImage" />
              <img src={GetTuned3} alt="Product image 3" className="appImage" />
            </AppImageSection>
          </ContentSection>
          <ContentSection>
            <h2>Privacy Policy</h2>
            <p>
              No personally identifiable information is being collected.
              Anonymous useage data is collected using Firebase Analytics. It is
              used to help understand how users are using the app and make
              improvements. Read the full privacy policy{" "}
              <Link to="privacy/">here</Link>.
            </p>
          </ContentSection>
          <ContentSection>
            <h2>Support</h2>
            <p>
              If you've found a bug or have a feature request, send me an{" "}
              <a href="mailto:support@carlhinkle.com?subject=GetTuned Support">
                email
              </a>
              .
            </p>
          </ContentSection>
        </article>
      </Container>
    </Layout>
  );
};

export default GetTuned;
